@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap');

* {
  margin: 0;
  font-family: 'Source Sans Pro', sans-serif;
  color: #313131;
}

.navbar {
  position: fixed;
  z-index: 1;
  top: 15px;
  width: 100%;
  text-align: right;
  /* padding-right: 50px; */
  right: 10px;
}

#title {
  font-size: 60px;
}
#logo {
  position:fixed; 
  top:0; 
  left:0;
  padding-top: 5px;
  padding-left: 15px;
  width: 40px; 
  height: auto;
}

h1 {
  font-size: 40px;
}

.App {
  display: flex;
  flex-direction: column;
  font-size: 18px;
}

.navbar .active {
  color: white;
  background-color: #e21313;
}

.navbtn {
  margin: 0px 5px;
  padding: 5px 15px;
  display: inline;
  border-radius: 8px;
  transition: 0.2s;
  background-color: rgba(255, 255, 255, 1); 
  box-shadow: 0 3px 5px -2px #838383; 
  text-decoration: none;
}

.navbtn:hover {
  color: white;
  background-color: #e21313;
  cursor: pointer;
}

.section {
  padding-left: 150px;
  padding-top: 100px;
  padding-bottom: 50px;
  padding-right: 150px;
  min-height: 100vh;
}

.home {
  min-height: 100vh;
  background-image: linear-gradient(135deg, #fdfcfb 0%, #e2d1c3 100%);
}

.slide-in {
  /* position: fixed; */
  animation: 1.2s ease-out 0s 1 slideInFromLeft;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateY(-50%);
  }
  100% {
    transform: translateX(0);
  }
}

#penn-csa {
  margin-top: 20px;
  width: 35%;
}

#instagram, #facebook {
  display: inline-block;
  font-size: 1.3em;
  margin-left: 25px;
}

#instagram:hover, #facebook:hover {
  cursor: pointer;
}

.mission {
  margin-top: 20px;
  width: 45%;
}

#csa {
  width: 50%;
  float: right;
}

#csa-mobile {
  width: 90%;
  margin: 10px 5%;
  display: none;
}

#listserv-btn {
  margin-top: 30px;
  padding: 5px 15px;
  border: none;
  font: inherit;
  color: white;
  background-color: #313131;
  cursor: pointer;
  border-radius: 8px;
  transition: 0.2s;
  box-shadow: 0 3px 5px -2px #838383;
  border-radius: 8px;
}

#listserv-btn:hover {
  color: #313131;
  background-color: #ffffff;
  cursor: pointer;
}
.events {
  background-image: linear-gradient(to right, #ffecd2 0%, #fcb69f 100%);
  display: flex;
  flex-direction: column;
}

#events-title, #board-title {
  margin: 10px 0px;
  width: 15%;
}

#events-title {
  margin-bottom: 20px;
}

#welcome-week {
  width: 100%;
}

.board {
  background-image: linear-gradient(90deg, #f9c6c8 0%, #f9dad1 99%, #f9dad1 100%);
}

.board-bios {
 margin: 5px -10px;
}

.card-holder {
  display: flex;
  overflow-x: auto;
}

.board-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}


.card {
  margin: 10px;
  float: left;
  width: 200px;
  height: 280px;
  text-align: center;
  background-color: rgba(255, 255, 255, .5);  
  box-shadow: 0 3px 5px -2px #c1c7cc;
  transition: all 0.8s ease;
  border-radius: 20px;
}

.card:hover {
  box-shadow: 16px 16px 16px 16px rgba(0,0,0,0.2);
}

.card-img {
  position: relative;
  float: left;
  width: 100%;
  margin-bottom: 10px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  width: 100%; 
  height: 200px;
  object-fit: cover;
}

#coffee-chat {
  width: 100%;
  text-align: center;
}

.fg {
  background-image: linear-gradient(to bottom, #dee4e9 0%, #dee4e9 1%, #e6dee9 100%);
}

#fg-title {
  margin: 10px 0px;
  width: 30%;
}

.footer {
  height: 150px;
  background-image: linear-gradient(90deg, #f9c6c8 0%, #f9dad1 99%, #f9dad1 100%);
  box-shadow: 0 3px 5px -2px #c1c7cc;
  text-align: center;
}

.modal {
  position: fixed;
  /* padding: 10px; */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1;
}

.modal-main {
  width: 60%;
  padding: 30px;
  position:fixed;
  background: white;
  height: auto;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  border-radius: 15px;
  display:flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-around;
}

.modal-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}

.modal-img img{
  width: 300px;
  height: auto;
}

.modal-text {
  max-width: 400px;
  padding: 20px;
  text-align: left;
}

.modal-text > .event-date { 
  padding-bottom: 10px;
}

.fade-in {
	opacity: 1;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: .3s;
}
.fade-in-slow {
	opacity: 1;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 2s;
}

@keyframes fadeInOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.modal-main h2 {
  margin-bottom: 10px;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.close-btn-wrapper {
  margin-top: 5px;
  justify-content: center;
  direction: ltr;
  display: flex;
  

}

.close-btn {
  padding: 5px 10px;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 0.85em;
  border: none;
  color: white;
  background-color: #313131;
  cursor: pointer;
  box-shadow: 0 3px 5px -2px #838383;
  border-radius: 8px;
}

#fg-wrapper {
  display: inline-block;
  width: 100%;
}

#fg-title {
  float: left;
  width: 35%;
}

#fg-title-wrapper {
  width: 100%;
}

#fg-card-wrapper {
  float: left;
  width: 65%;
  margin-top: 10px;
}

.fg-card {
  margin-top: 20px;
  float: left;
  height: 50px;
  width: 22%;
  text-align: center;
  font-size: 0.9em;
  margin-right: 3%;
}

.fg-card p {
  background-color: #313131;
  color: white;
  padding: 10px;
  border-radius: 12px;

}

.form {
  margin-top: 10px;
  float: right;
  padding: 20px 30px;
  width: 25%;
  background-color: white;
  font-size: 0.9em;
  box-shadow: 0 3px 5px -2px #989898;
}

.form p {
  color: #313131;
}

.form input {
  background-color: white;
  border: 1px solid #989898;
  width: 100%;
  height: 30px;
  margin: auto;
  margin-bottom: 20px;
  margin-top: 5px;
  box-sizing: border-box;
}

#fg-btn {
  padding: 5px 10px;
  font-size: 1em;
  border: none;
  color: white;
  background-color: #313131;
  cursor: pointer;
  box-shadow: 0 3px 5px -2px #838383;
  margin-bottom: 10px;
}

#fg-pic {
  width: 100%;
  margin-top: 20px ;
}

#contact-us {
  margin-bottom: 30px;
}

.links {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

@media only screen and (max-width: 960px){
  .navbtn {
    font-size: 12px;
  }
}

@media only screen and (max-width: 330px){
  /* iphone 5 */
  .navbtn {
    font-size: 5px;
    padding: 3px 8px;
  }
}


@media only screen and (max-width: 520px){
  .modal-main {
    width: 80%;
    padding: 10px;
  }
  
  .modal-img img{
    width: 200px;
    height: auto;
  }
  
  .modal-text {
    max-width: 400px;
    padding: 10px;
  }
  
  .modal-text > p {
    font-size: 14px;
  }

}

@media only screen and (max-width: 750px){
  .navbtn {
    font-size: 15px;
  }

  #logo { 
    visibility: hidden;
  }
  
  .section {
    padding-left: 20px;
    padding-top: 100px;
    padding-bottom: 50px;
    padding-right: 20px;
    min-height: 100vh;
  }

  .mission {
    width: 100%;
  }

  #csa {
    display: none;
    z-index: 0;
  }

  #csa-mobile {
    display: initial;
  }

  #penn-csa {
    width: 80%;
  }

  #board-title, #events-title {
    width: 40%;
  }

  #fg-title {
    width: 80%;
  }

  #fg-card-wrapper {
    width: 100%;
  }

  #fg-summary {
    margin-bottom: 10px;
  }

  .fg-card {
    width: 45%;
    margin-left: 2.5%;
    margin-right: 2.5%;
    margin-top: 10px;
  }

  #fg-pic {
    width: 100%;
    margin: 15px 0px;
  }

  .form-wrapper {
    text-align: center;
  }

  .form {
    display: inline-block;
    width: 70%;
    float: none;
  }
  
}
